import { default as defaultGroupList } from '$lib/data/categories.json';
// import { addMessages, getLocaleFromNavigator, init } from 'svelte-i18n';
import { default as languages } from '$lib/data/reference-data/languages.json';
import type { CategoryGroupModel } from '$lib/models/category.models';
import { CategoryFactory } from '$lib/models/factory';
import type { C9Map } from '$lib/models/object.models';
import type { ReferenceDataModel } from '$lib/models/reference-data.models';
import { asArray } from '@rigu/js-toolkit';

export const CASA9_URL = 'https://casa9.eu';
export const CASA9EU_COOKIE_CONSENT = 'CASA_9_CONSENT';
export const cookiePath = '/casa9.eu/';


// GOOGLE Analytics ID
export const GA_MEASUREMENT_ID = 'G-3FGY6K5VH9';

const langTranslationKey = languages.translation;

export const REF_DATA: C9Map<ReferenceDataModel[]> = {
    languages: asArray(languages.data).map((langCode) => ({
        code: langCode.toUpperCase(),
        name: langTranslationKey + langCode,
    })),
};

export const initializeC9 = (): void => {
    asArray(defaultGroupList).forEach((categoryGroup: Partial<CategoryGroupModel>) => CategoryFactory.group(categoryGroup));
    // addMessages('ro', i18_ro);

    // init({
    // 	fallbackLocale: 'ro',
    // 	initialLocale: getLocaleFromNavigator()
    // });
};

// export const CASA9_BASE_PATH = 'http://localhost:5173';
// export const CASA9_BASE_PATH = 'https://casa9.fast-dev.md';
// export const CASA9_BASE_PATH = 'https://casa9.eu';
// export const CASA9_BASE_PATH = 'https://casa9eu.appspot.com';
